const ANCHORS = {
  AUCTION: 'auction',
  BECOME_BOUTIQUE: 'becomeBoutique',
  COMMISSION: 'commission',
  DELIVERY_AND_REFUND: 'shippingReturns',
  GUARANTEE: 'guaranteeAuthenticity',
  HOW_TO_SELL: 'howToSell',
  HOW_TO_BUY: 'howToBuy',
  OSKELLY_EXPERTISE: 'expertiseOskelly',
  SAFE_DEAL: 'safeDeal',
  TIP: 'tip',
};

const sellerAnchorList = [
  {
    anchor: ANCHORS.HOW_TO_SELL,
    label: 'components.info.anchors.howToSell',
  },
  {
    anchor: ANCHORS.OSKELLY_EXPERTISE,
    label: 'components.info.anchors.oskellyExpertise',
  },
  {
    anchor: ANCHORS.SAFE_DEAL,
    label: 'components.info.anchors.safeDeal',
  },
  {
    anchor: ANCHORS.COMMISSION,
    label: 'components.info.anchors.comission',
  },
  {
    anchor: ANCHORS.BECOME_BOUTIQUE,
    label: 'components.info.anchors.becomeBoutique',
  },
  {
    anchor: ANCHORS.TIP,
    label: 'components.info.anchors.tip',
  },
];
const buyerAnchorList = [
  {
    anchor: ANCHORS.HOW_TO_BUY,
    label: 'components.info.anchors.howToBuy',
  },
  {
    anchor: ANCHORS.GUARANTEE,
    label: 'components.info.anchors.guarantee',
  },
  {
    anchor: ANCHORS.SAFE_DEAL,
    label: 'components.info.anchors.safeDeal',
  },
  {
    anchor: ANCHORS.DELIVERY_AND_REFUND,
    label: 'components.info.anchors.deliveryAndRefund',
  },
  {
    anchor: ANCHORS.AUCTION,
    label: 'components.info.anchors.auction',
    alternativeLabel: 'components.info.anchors.auction.alternative',
  },
  {
    anchor: ANCHORS.TIP,
    label: 'components.info.anchors.tip',
  },
];

export {
  ANCHORS,
  buyerAnchorList,
  sellerAnchorList,
};
